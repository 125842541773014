<template>
    <div>
        <h1>Daniel Obode.</h1>
        <h5>Software Engineer.</h5>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
h5 {
    font-weight: 700;
}
</style>